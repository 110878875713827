import { TEL_1 } from '@utils/constants'
import Link from 'next/link'
import Header from './Header'
import Image from 'next/image'
import heroImg from '@public/hero.jpg'

export const Hero = () => (
    <div className="container mx-auto max-w-7xl ">
        <div className="relative bg-white overflow-hidden">
            <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                <svg
                    className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
                    fill="currentColor"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="none"
                    aria-hidden="true"
                >
                    <polygon points="50,0 100,0 50,100 0,100" />
                </svg>

                <Header />

                <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                    <div className="sm:text-center lg:text-left">
                        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                            <span className="block xl:inline">
                                Consiliere accidente auto
                            </span>{' '}
                            <span className="block text-indigo-600 xl:inline">
                                Galati
                            </span>
                        </h1>
                        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 whitespace-pre-wrap">
                            Ai facut accident? Ai lovit sau ai fost lovit de o alta
                            masina? Contacteaza-ne si te vom consilia pentru a
                            rezolva cat mai repede situatia.
                            <br />
                            Deschidem dosarul de dauna, iti preluam masina si iti
                            oferim alta la schimb pe toata durata reparatiei.
                        </p>

                        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                            <div className="rounded-md shadow">
                                <a
                                    href={TEL_1.raw}
                                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                                >
                                    Suna acum
                                </a>
                            </div>
                            <div className="mt-3 sm:mt-0 sm:ml-3">
                                <Link href="/despre-noi">
                                    <a className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                                        Mai multe &rarr;
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <Image
                    src={heroImg}
                    alt="Consiliere accidente auto Galati"
                    layout="fill"
                    objectFit="cover"
                    placeholder="blur"
                />
            </div>

            <div className="lg:hidden">
                <Image
                    src={heroImg}
                    layout="responsive"
                    objectFit="cover"
                    placeholder="blur"
                    width={100}
                    height={40}
                    alt="Consiliere accidente auto Galati"
                />
            </div>
        </div>
    </div>
)
export default Hero
